<template>
  <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" :show-close="false" width="60%" @close="handleClose">
    <el-row class="row-header">
      <el-card class="card-top">
        <h4>基础信息</h4>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="手机号:" prop="tel">
                <el-input @input="changeTel" placeholder="请输入手机号" :disabled="isChange" v-model="form.tel"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="用户名:" prop="customerName">
                <el-select v-model="form.customerName" :disabled="isChange" @change="changeCustomerNameSelect" placeholder="请选择用户">
                  <el-option v-for="(item, index) in popList" :key="index" :label="item.userName" :value="item.userId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="证件类型:" prop="identificationType">
                <el-select @change="changeData" v-model="form.identificationType" :disabled="isChange" placeholder="请选择证件类型">
                  <el-option v-for="(item, index) in certificateList" :key="index" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="证件号码:" prop="identifycationNo">
                <el-input :disabled="isChange" placeholder="请输入证件号码" @input="inputBlur(form.identifycationNo)" v-model="form.identifycationNo"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="性别:" prop="sex">
                <el-radio-group @change="changeSex" :disabled="isChange" v-model="form.sex">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="0">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="年龄:" prop="age">
                <el-input @input="changeData" placeholder="请输入年龄" :disabled="isChange" v-model="form.age"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="家庭住址:">
                <el-input @input="changeData" placeholder="请输入家庭住址" :disabled="isChange" v-model="form.addr"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-row>
    <el-row>
      <el-card>
        <h4>产品信息</h4>
        <el-form ref="form" :model="form.customerProductVo" :rules="rules" label-width="120px">
          <el-row>
            <el-col>
              <el-form-item label="订单号:" prop="orderId">
                <el-input v-if="info" :disabled="isChange" v-model="form.customerProductVo.orderNo"></el-input>
                <el-select v-model="form.customerProductVo.orderId" v-else :disabled="isChange" @change="changeOrder" placeholder="请选择订单号">
                  <el-option v-for="item in orderList" :key="item.id" :label="item.orderNo" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="产品名称:" prop="productId">
                <el-select @change="handleChangeProduct" disabled v-model="form.customerProductVo.productId" placeholder="请选择产品名称">
                  <el-option label="全部" :value="0"></el-option>
                  <el-option v-for="item in productList" :key="item.id" :label="item.productName" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="会员类型:" prop="memberCode">
                <el-radio-group @change="changeSelect" :disabled="true" v-model="form.customerProductVo.memberCode">
                  <el-radio v-for="(item, index) in rightsType" :key="index" :label="item.dictionaryCode">{{
                    item.dictionaryName
                  }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="0">
            <div>
              <el-col :span="8">
                <el-form-item label="家庭名称:">
                  <el-select @change="handleChangeteam" :disabled="handleInput" v-model="form.customerProductVo.homeId" placeholder="请选择家庭名称">
                    <el-option label="-- 请选择 --" :value="0"></el-option>
                    <el-option v-for="(item, index) in homeList" :key="index" :label="item.teamName" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="1">
                <el-button @click="addNewTeam('JT')" :disabled="handleInput" icon="el-icon-plus"></el-button>
              </el-col>
            </div>
            <el-col :span="14">
              <el-form-item label="企业名称:">
                <el-select @change="handleChangeteam1" :disabled="handleInput2" v-model="form.customerProductVo.enterpriseId" placeholder="请选择企业名称">
                  <el-option label="-- 请选择 --" :value="0"></el-option>
                  <el-option v-for="(item, index) in enterpriseList" :key="index" :label="item.teamName" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <el-button @click="addNewTeam('JY')" :disabled="handleInput2" icon="el-icon-plus"></el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="服务机构:" prop="companyId">
                <el-select @change="handleChangeCompany" :disabled="isChange" v-model="form.customerProductVo.companyId" placeholder="请选择服务机构">
                  <el-option label="-- 请选择 --" :value="0"></el-option>
                  <el-option v-for="(item, index) in companyList" :key="index" :label="item.companyName" :value="item.companyId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="服务管家:" prop="houseKeeperId">
                <el-select @change="handleChangeDoctor" v-model="form.customerProductVo.houseKeeperId" placeholder="请选择服务管家">
                  <el-option label="-- 请选择 --" :value="0"></el-option>
                  <el-option v-for="(item, index) in houseKeeperList" :key="index" :label="item.employeeName" :value="item.employeeId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="服务医生:" prop="houseDocterId">
                <el-select @change="handleChangeqiye" v-model="form.customerProductVo.houseDocterId" placeholder="请选择服务医生">
                  <el-option label="-- 请选择-- " :value="0"></el-option>
                  <el-option v-for="(item, index) in doctorList" :key="index" :label="item.employeeName" :value="item.employeeId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="服务开始时间:" prop="startTime">
                <el-date-picker :disabled="isChange" @change="changeStartTime" type="date" value-format="yyyy-MM-dd" placeholder="服务开始时间" v-model="form.customerProductVo.startTime" style="width: 100%"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="服务结束时间:" prop="endTime">
                <el-date-picker :disabled="true" type="date" @change="changeEndTime" placeholder="服务结束时间" v-model="form.customerProductVo.endTime" style="width: 100%"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="积分:">
                <el-input placeholder="请输入积分" :disabled="isChange" @input="changeInput" v-model="form.customerProductVo.integral"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureCommit()">确 定</el-button>
    </span>
    <teamMnagement :type="type" :info="infomation" :onlyLook="onlyLook" v-if="showTeam" @closeteamManagement="closeteamManagement"></teamMnagement>
  </el-dialog>
</template>
<script>
import teamMnagement from '@/views/customerManagement/component/teamMnagement'
import { postRequest, getAllInstitutionsInfo, postRequestAnthor } from '@/api'
import { formatTime } from '@/utils/utils'
import { isvalidPhone, isvalidIdNum } from 'src/utils/vaildate'
var validPhone = (rule, val, callback) => {
  let value = val.replace(/[\u200b-\u200f\uFEFF\u202a-\u202e]/g, "")
  if (!value) {
    callback(new Error('请输入电话号码'))
  } else if (!isvalidPhone(value)) {
    callback(new Error('请输入正确的11位手机号码'))
  } else {
    callback()
  }
}
var validIdNum = (rule, value, callback) => {
  if (!value) {
    callback(new Error('证件号码不能为空'))
  } else if (!isvalidIdNum(value)) {
    callback(new Error('证件号码不正确'))
  } else {
    callback()
  }
}

export default {
  data() {
    return {
      dialogVisible: true,
      productList: [],
      rightsType: [],
      handleInput: true,
      handleInput2: true,
      isChange: false,
      doctorList: [],
      infomation: '',
      homeList: [],
      showTeam: false,
      enterpriseList: [],
      houseKeeperList: [],
      orderList: [],
      type: '',
      companyList: [],
      disabled: false,
      title: '新增客户',
      certificateList: [],
      type: 1,
      tableData: [],
      popList: [],
      form: {
        identifycationNo: '',
        customerProductVo: {
          homeId: '',
          enterpriseId: '',
          endTime: '',
          houseDocterId: '',
        },
        customerProductVos: [],
        customerName: '',
        sex: '',
      },
      rules: {
        tel: [{ required: true, trigger: 'blur', validator: validPhone }],
        identifycationNo: [{ required: true, trigger: 'blur', validator: validIdNum }],
        startTime: [{ required: true, message: '请选择服务开始时间', trigger: 'change' }],
        customerName: [{ required: true, message: '请选择客户', trigger: 'input' }],
        identificationType: [{ required: true, message: '请选择证件类型', trigger: 'input' }],
        orderId: [{ required: true, message: '请选择订单号', trigger: 'input' }],
        productId: [{ required: true, message: '请选择产品名称', trigger: 'input' }],
        memberCode: [{ required: true, message: '请选择会员类型', trigger: 'input' }],
        sex: [{ required: true, message: '请选择客户性别', trigger: 'input' }],
        age: [{ required: true, message: '请输入客户年龄', trigger: 'input' }],
        companyId: [{ required: true, message: '请选择服务机构', trigger: 'input' }],
        houseKeeperId: [{ required: true, message: '请选择服务管家', trigger: 'input' }],
        houseDocterId: [{ required: true, message: '请选择服务医生', trigger: 'input' }],
      },
    }
  },
  components: { teamMnagement },
  props: ['info', 'phone', 'phoneTuoM'],
  mounted() {
    if (this.info != '') {
      this.isChange = true
      this.handleInput = true
      this.handleInput2 = true
      if (this.info.customerProductVos[0].memberCode == 'JT') {
        console.log('JT')
        this.info.customerProductVos[0].homeId = this.info.customerProductVos[0].teamId
      } else if (this.info.customerProductVos[0].memberCode == 'JY') {
        console.log('JY')
        this.info.customerProductVos[0].enterpriseId = this.info.customerProductVos[0].teamId
      }
      if (this.info.customerProductVos[0].memberCode) this.title = '修改客户'
      this.info.customerProductVos[0].orderNo = this.info.orderNo
      this.form = this.info
      this.form.identifycationNo ? this.inputBlur(this.form.identifycationNo) : ''
      // this.inputBlur(this.form.identifycationNo)
      this.info.customerProductVos[0].companyId = Number(this.info.customerProductVos[0].companyId)
      this.form.customerProductVo = this.info.customerProductVos[0]
      console.log(this.info, 'info')
      postRequest('/orderComputer/queryOrderByTel', { tel: this.info.tel, stuts: 1 }).then(res => {
        console.log(res, '根据手机号查询订单号')
        this.orderList = res
        this.form.customerProductVo.orderId = this.info.orderId
      })
    }
    if (this.phone) {
      // 手机号查询
      this.form.tel = this.phoneTuoM
      postRequestAnthor('/customer/queryCustomerInfoByTel', { tel: this.phone }).then(res => {
        console.log(res, '根据罗文提供的手机号查询接口查询客户信息')
        this.popList = res
        // this.form.customerName = res.userName
        // this.form.identificationType = res.identificationType
        // this.form.identifycationNo = res.identificationNo
        // this.form.age = res.age
        // this.form.tel = this.phone
        // this.form.sex = Number(res.sex)
        // this.form.addr = res.address
        // this.form.userId = res.userId
        // this.form.userName = res.userName
      })
      postRequest('/orderComputer/queryOrderByTel', { tel: this.phone }).then(res => {
        console.log(res, '根据旭哥的电话查询接口查询的结果')
        this.orderList = res
        if (this.orderList.length <= 0) {
          this.$message({
            message: '暂未购买产品, 无法转为客户',
            type: 'warning',
          })
        }
      })
    }
    // 获取产品列表
    postRequest('/product/queryProduct/', {}).then(res => {
      console.log(res, '产品列表')
      this.productList = res
    })
    // 团体类别获取
    postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '06' }).then(res => {
      console.log(res, '家庭、精英、SVIP')
      this.rightsType = res
    })
    postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '01' }).then(res => {
      console.log(res, '证件')
      this.certificateList = res
    })
    // 管家列表
    postRequest('Employee/queryEmployee', { employeeDuty: '0303' }).then(res => {
      console.log(res, '管家列表')
      this.houseKeeperList = res
    })
    // 医生列表
    postRequest('/Employee/queryEmployee', { employeeDuty: '0301' }).then(res => {
      console.log(res, '医生列表')
      this.doctorList = res
    })
    postRequest('/team/queryTeamInfo', { teamDictionaryCode: 'JT' }).then(res => {
      console.log(res, '家庭名称')
      this.homeList = res
    })
    postRequest('/team/queryTeamInfo', { teamDictionaryCode: 'JY' }).then(res => {
      console.log(res, '精英用户')
      this.enterpriseList = res
    })
    // 查询所有机构
    postRequestAnthor('/company/queryCompany', {}).then(res => {
      console.log(res, '机构')
      this.companyList = res
    })
  },
  methods: {
    handleClose() {
      this.$emit('closeAdd')
    },
    changeInput() {
      this.$forceUpdate()
    },
    changeData(val) {
      console.log(val, '身份证')
      this.$forceUpdate()
    },
    inputBlur(val) {
      console.log(val)
      this.$forceUpdate()
      if (val.length == 18) {
        var myDate = new Date()
        var month = myDate.getMonth() + 1
        var day = myDate.getDate()
        this.form.age = myDate.getFullYear() - val.substring(6, 10) - 1
        if (val.substring(10, 12) < month || (val.substring(10, 12) == month && val.substring(12, 14) <= day)) {
          this.form.age++
        }
        console.log(this.form.age)
        if (parseInt(val.substr(16, 1)) % 2 == 1) {
          this.form.sex = '1'
        } else {
          this.form.sex = '0'
        }
        console.log(this.form.sex)
      } else {
        this.form.age = ''
        this.form.sex = ''
      }
    },
    // showCustomer(){
    // postRequestAnthor('/customer/queryCustomerInfoByTel', {tel: this.form.tel}).then(res=>{
    //   console.log(res, '根据罗文提供的手机号查询接口查询客户信息')
    //   this.popList = res
    // })
    // postRequest('/orderComputer/queryOrderByTel',{tel: this.form.tel}).then(res=>{
    //   console.log(res, '根据旭哥的电话查询接口查询的结果')
    //   this.orderList = res
    // })
    // },
    changeTel(val) {
      this.$forceUpdate()
      console.log(val)
      if (val.length == 11) {
        postRequestAnthor('/customer/queryCustomerInfoByTel', { tel: val }).then(res => {
          console.log(res, '根据罗文提供的手机号查询接口查询客户信息')
          this.popList = res
        })
        postRequest('/orderComputer/queryOrderByTel', { tel: val }).then(res => {
          console.log(res, '根据旭哥的电话查询接口查询的结果')
          this.orderList = res
        })
      }
      this.form.customerName = ''
      this.form.identificationType = ''
      this.form.identifycationNo = ''
      this.form.age = ''
      this.form.sex = ''
      this.form.addr = ''
      this.form.userId = ''
      this.form.userName = ''
      this.form.customerProductVo.productId = ''
      this.form.customerProductVo.productName = ''
      this.form.customerProductVo.memberCode = ''
      this.form.customerProductVo.integral = ''
      this.form.customerProductVo.companyId = ''
      this.form.customerProductVo.orderId = ''
    },
    handleChangeqiye(val) {
      this.$forceUpdate()
      this.doctorList.map(item => {
        if (item.employeeId == val) {
          this.form.customerProductVo.houseDocterName = item.employeeName
        }
      })
    },
    handleChangeDoctor(val) {
      this.houseKeeperList.map(item => {
        if (item.employeeId == val) {
          this.form.customerProductVo.houseKeeperName = item.employeeName
        }
      })
    },
    closeteamManagement() {
      this.showTeam = false
      postRequest('/team/queryTeamInfo', { teamDictionaryCode: this.form.customerProductVo.memberCode }).then(res => {
        if (this.form.customerProductVo.memberCode == 'JT') {
          this.homeList = res
        }
        if (this.form.customerProductVo.memberCode == 'JY') {
          this.enterpriseList = res
        }
      })
    },
    addNewTeam(val) {
      this.showTeam = true
      this.infomation = ''
      this.onlyLook = false
      this.type = val
    },
    changeOrder(val) {
      console.log(val)
      this.disabled = true
      this.orderList.map(item => {
        if (item.id == val) {
          console.log(item)
          this.form.limitTime = item.timeLimit
          this.form.customerProductVo.productId = item.orderCode
          this.form.customerProductVo.companyId = Number(item.companyId)
          this.form.customerProductVo.productName = item.orderTitle
          this.form.customerProductVo.memberCode = item.teamType
          this.form.customerProductVo.integral = item.integral
          this.changeSelect(item.teamType)
          // if (item.teamType == 'JT') {
          //   this.handleInput2 = true
          //   this.handleInput = false
          //   postRequest('/team/queryTeamInfo', {teamDictionaryCode: 'JT'}).then(res=>{
          //     console.log(res, '家庭用户')
          //     this.homeList = res
          //   })
          // }
          // if (item.teamType == 'JY') {
          //   this.handleInput = true
          //   this.handleInput2 = false
          //   postRequest('/team/queryTeamInfo', {teamDictionaryCode: 'JY'}).then(res=>{
          //     console.log(res, '精英用户')
          //     this.enterpriseList = res
          //   })
          // }
          // if (item.teamType == 'SVIP'|| item.teamType == 'GR' || item.teamType == 'PT') {
          //   this.handleInput2 = true
          //   this.handleInput = true
          // }
        }
      })
    },
    handleChangeteam(val) {
      this.$forceUpdate()
      this.homeList.map(item => {
        if (item.id == val) {
          this.form.customerProductVo.teamName = item.teamName
          // this.$set(this.form.customerProductVo, 'teamName', item.teamName)
        }
      })
    },
    handleChangeCompany(val) {
      console.log(val)
      this.$forceUpdate()
      this.companyList.map(item => {
        if (item.companyId == val) {
          this.form.customerProductVo.companyName = item.companyName
        }
      })
    },
    handleChangeteam1(val) {
      this.$forceUpdate()
      this.enterpriseList.map(item => {
        if (item.id == val) {
          this.form.customerProductVo.teamName = item.teamName
          console.log(this.form.customerProductVo.teamName, '123')
        }
      })
    },
    handleChangeProduct(val) {
      console.log(typeof val)
      this.$forceUpdate()
      this.productList.map(item => {
        if (item.id == val) {
          this.form.customerProductVo.productName = item.productName
        }
      })
    },
    changeSex(val) {
      console.log(val)
    },
    changeCustomerNameSelect(val) {
      this.$forceUpdate()
      console.log(val)
      this.popList.map(item => {
        if (item.userId == val) {
          this.form.customerName = item.userName
          this.form.identificationType = item.identificationType
          this.form.identifycationNo = item.identificationNo
          this.form.age = item.age
          this.form.sex = Number(item.sex)
          this.form.addr = item.address
          this.form.userId = item.userId
          this.form.userName = item.userName
          this.inputBlur(item.identificationNo)
        }
      })
    },
    changeSelect(val) {
      this.$forceUpdate()
      console.log(val, 'val')
      if (val == 'JT') {
        this.handleInput2 = true
        this.handleInput = false
        postRequest('/team/queryTeamInfo', { teamDictionaryCode: 'JT' }).then(res => {
          console.log(res, '家庭用户')
          this.homeList = res
        })
      }
      if (val == 'JY') {
        this.handleInput = true
        this.handleInput2 = false
        postRequest('/team/queryTeamInfo', { teamDictionaryCode: 'JY' }).then(res => {
          console.log(res, '精英用户')
          this.enterpriseList = res
        })
      }
      if (val == 'SVIP' || val == 'GR' || val == 'PT') {
        this.handleInput2 = true
        this.handleInput = true
      }
    },
    async changeStartTime(val) {
      console.log(val)
      if (this.form.limitTime == null || this.form.limitTime == '') {
        this.form.limitTime = 0
      }
      await postRequest(`/customer/getProductEndTime?startDate=${val}&num=${this.form.limitTime}`).then(res => {
        console.log(res, '结束时间')
        this.form.customerProductVo.endTime = res
        this.$forceUpdate()
      })
    },
    changeEndTime() {
      this.$forceUpdate()
    },
    changeType(val) {
      this.type = val
    },
    chooseGender(val) {
      console.log(val, 'genderType')
      this.form.sex = val
    },
    sureCommit() {
      // this.$refs[form].validate((valid) => {
      //     if (valid) {
      console.log(
        this.form.tel,
        'tel',
        this.form.customerName,
        'customerName',
        this.form.identificationType,
        'identificationType',
        this.form.identifycationNo,
        'identifycationNo',
        this.form.sex,
        'sex',
        this.form.age,
        'age',
        this.form.customerProductVo.orderId,
        'orderId',
        this.form.customerProductVo.productId,
        'productId',
        this.form.customerProductVo.memberCode,
        'memberCode',
        this.form.customerProductVo.startTime,
        'startTime',
      )
      console.log(this.form.customerProductVo.houseKeeperId, this.form.customerProductVo.houseDocterId)
      if (this.info != '') {
        if (this.form.customerProductVo.enterpriseId) {
          this.form.customerProductVo.teamId = this.form.customerProductVo.enterpriseId
        } else if (this.form.customerProductVo.homeId) {
          this.form.customerProductVo.teamId = this.form.customerProductVo.homeId
        } else {
          this.form.customerProductVo.teamId = ''
        }
        this.form.orderId = this.form.customerProductVo.orderId
        this.form.customerProductVos = [this.form.customerProductVo]
        postRequest('/customer/update', this.form).then(res => {
          console.log(res, '修改')
          this.$message({
            message: '修改成功',
            type: 'success',
          })
          this.handleClose()
        })
      } else {
        if (
          this.form.tel == undefined ||
          this.form.customerName == '' ||
          this.form.identificationType == undefined ||
          this.form.identifycationNo == undefined ||
          this.form.sex == '' ||
          this.form.age == undefined ||
          this.form.customerProductVo.orderId == undefined ||
          this.form.customerProductVo.productId == undefined ||
          this.form.customerProductVo.memberCode == undefined ||
          this.form.customerProductVo.startTime == undefined ||
          this.form.customerProductVo.houseKeeperId == undefined ||
          this.form.customerProductVo.houseDocterId == ''
        ) {
          this.$message({
            message: '请完善客户信息',
            type: 'warning',
          })
        } else if (
          this.form.tel != undefined &&
          this.form.customerName != '' &&
          this.form.identificationType != undefined &&
          this.form.identifycationNo != undefined &&
          this.form.sex != '' &&
          this.form.age != undefined &&
          this.form.customerProductVo.orderId != undefined &&
          this.form.customerProductVo.productId != undefined &&
          this.form.customerProductVo.memberCode != undefined &&
          this.form.customerProductVo.startTime != undefined &&
          this.form.customerProductVo.houseKeeperId != undefined &&
          this.form.customerProductVo.houseDocterId != ''
        ) {
          if (this.form.customerProductVo.enterpriseId) {
            this.form.customerProductVo.teamId = this.form.customerProductVo.enterpriseId
          } else if (this.form.customerProductVo.homeId) {
            this.form.customerProductVo.teamId = this.form.customerProductVo.homeId
          } else {
            this.form.customerProductVo.teamId = ''
          }
          this.form.orderId = this.form.customerProductVo.orderId
          this.form.customerProductVos = [this.form.customerProductVo]
          postRequest('/customer/addSave/', this.form).then(res => {
            console.log(res, '新增or修改')
            this.$message({
              message: '新增成功',
              type: 'success',
            })
            this.handleClose()
          })
        }
      }
      // } else {
      //   console.log('error submit!!');
      //   return false;
      // }
      // });
    },
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
.el-date-editor--date {
  width: 100%;
}
.el-input__inner {
  width: 100%;
}
img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.searchFrom {
  display: flex !important;
  justify-content: space-between !important;
}
.main {
  margin-top: 20px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bottom {
    margin-top: 10px;
  }
}
.card-top {
  margin-top: -20px;
  margin-bottom: 20px;
}
h4 {
  margin-top: 0;
}
.row-header {
  margin-top: -20px;
}
.el-dialog__footer {
  margin-top: -20px !important;
}
</style>
