// 手机号码验证
export function isvalidPhone(str) {
  const reg = /^1[23456789]\d{9}$/
  // const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
  return reg.test(str)
}

// 身份证号验证
export function isvalidIdNum(str) {
  // const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  // const reg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  const reg = /(^[1-9]\d{5}(((1[89]|20)\d{2}(((0[13578]|1[0-2])(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)(0[1-9]|[12][0-9]|30))|(02(0[1-9]|[1][0-9]|2[0-8]))))|((((1[89]|20)(0[48]|[2468][048]|[13579][26]))|((19|20)00))0229))\d{3}(\d|X|x)$)/
  return reg.test(str)
}
export function isDecimal (str) {
  const reg = /^(1|(0(.\d{1,10})?))$/
  return reg.test(str)
}

// 
export function isNumber (str) {
  const reg = /^[0-9]+(.[0-9]{2})?$/
  return reg.test(str)
}

export function format (num) {
  const reg = /\d{1,3}(?=(\d{3})+$)/
  return reg.test(num)
}

export function integer () {
  const reg = /^[1-9]\d*$/
}